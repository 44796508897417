/* esversion: 6 */

export function DommertypeInfo(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            datasource: datasource_url,
            pagesize: 20,
        }),

        command_buttons: {
            add: false,
            csv: {
                filename: 'dommertyper.csv',
            }
        },
        

        construct_table: function (location, downloadwidget, ds) {
            dk.info("constructing table...", location, downloadwidget);
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    name: {
                        label: 'Navn',
                        format: function (val, rec) {
                            if (rec.url) {
                                return dk.$('<a/>').attr('href', `${rec.url}`).text(val);
                            } else {
                                return val;
                            }
                        }
                    },
                    count: {label: 'Registrerte i år'},
                }
            });
        }
    });
}
